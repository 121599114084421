<template>

    <div>

        <div class="body">

            <div class="header_btn">
                <div class="title">{{$i18n.t('product.text32')}}</div>
                <el-button class="btn" size="small" type="primary" plain @click="showStandard"
                           :disabled="productData.status == 3 || standardList.length === 0">{{$i18n.t('product.text32')}}</el-button>
                <el-button class="btn" size="small" style="margin-right: 10px;" type="primary" plain
                           @click="leadingOut">
                    {{$i18n.t('product.text33')}}
                </el-button>
            </div>

            <el-table
                ref="effectStandardTable"
                class="effect_standard_table"
                border
                :span-method="objectSpanMethod"
                :data="effectStandardList"
                :header-cell-style="{background:'#eef1f6',color:'rgba(0, 0, 0 ,.85)'}"
                :cell-style="{color:'rgba(0, 0, 0 ,.65)'}"
                v-loading="loadingStandard"
                :style="{
                    width: '100%'
                }">

                <el-table-column
                    min-width="50"
                    prop="serviceId"
                    label="SID">
                </el-table-column>

                <el-table-column
                    prop="effectName"
                    :label="$i18n.t('product.text34')">
                </el-table-column>

                <el-table-column
                    prop="effectRemarks"
                    :label="$i18n.t('product.text35')">
                </el-table-column>

                <el-table-column
                    prop="attributeName"
                    :label="$i18n.t('product.text36')">
                </el-table-column>

                <el-table-column
                    min-width="50"
                    prop="attributeId"
                    label="AID">
                </el-table-column>

                <el-table-column
                    prop="attributeRemarks"
                    :label="$i18n.t('product.text37')">
                </el-table-column>

                <el-table-column
                    :label="$i18n.t('product.text38')">
                    <template slot-scope="scope">
                        {{ $config.getMsgItemUtil($message.formatList, scope.row.format, 'id', 'name') }}
                    </template>
                </el-table-column>

                <el-table-column
                    min-width="140"
                    :label="$i18n.t('product.text39')">
                    <template slot-scope="scope">
                        <span style="white-space: pre-line;">{{ scope.row.remarks }}</span>
                    </template>
                </el-table-column>

                <el-table-column
                    :label="$i18n.t('product.text40')">
                    <template slot-scope="scope">
                        {{ $config.getMsgItemUtil($message.authorityList, scope.row.authority, 'id', 'name') }}
                    </template>
                </el-table-column>

                <el-table-column
                    :label="$i18n.t('product.text41')"
                    prop="remark">
                </el-table-column>

                <el-table-column width="100" :label="$i18n.t('product.text42')" :key="Math.random()">
                    <template slot-scope="scope">
                        <div>
                            <el-button type="text" size="small" @click="editAttribute(scope.row, 1)"  v-if="productData.status !== 3">{{$i18n.t('product.text43')}}</el-button>
                            <el-button type="text" size="small" @click="editAttribute(scope.row, 2)">{{$i18n.t('product.text44')}}</el-button>
<!--                            <el-button type="text" size="small" @click="delAttribute(scope.row)" style="color:#d45c34;">-->
<!--                                删除-->
<!--                            </el-button>-->
                        </div>
                    </template>
                </el-table-column>

                <el-table-column width="200" :label="$i18n.t('product.text222')" :key="Math.random()">
                    <template slot-scope="scope">
                        <div>
<!--                            <el-button type="text" size="small" @click="addAttribute(scope.row)"-->
<!--                                       v-show="scope.row.rowspan < 20" v-if="productData.status !== 3">添加属性-->
<!--                            </el-button>-->
                            <el-button type="text" size="small" @click="editEffect(scope.row, 0)" v-if="productData.status !== 3">{{$i18n.t('product.text43')}}</el-button>
                            <el-button type="text" size="small" style="color:#d45c34;" @click="delEffect(scope.row)" v-if="productData.status !== 3">{{$i18n.t('product.text45')}}</el-button>
                            <el-button type="text" size="small" @click="editEffect(scope.row, 1)">{{$i18n.t('product.text44')}}</el-button>
                        </div>
                    </template>
                </el-table-column>

            </el-table>

            <div class="header_btn" style="margin-top: 10px;">
                <div class="title">{{$i18n.t('product.text46')}}</div>
                <el-button class="btn" size="small" type="primary" plain @click="showCustom"
                           :disabled="productData.status == 3">{{$i18n.t('product.text46')}}
                </el-button>
            </div>

            <el-table
                ref="effectTable"
                class="effect_table"
                border
                :span-method="objectSpanMethod"
                :data="effectList"
                @cell-mouse-enter="cellMouseEnter"
                @cell-mouse-leave="cellMouseLeave"
                :row-class-name="tableRowClassName"
                :header-cell-style="{background:'#eef1f6',color:'rgba(0, 0, 0 ,.85)'}"
                :cell-style="{color:'rgba(0, 0, 0 ,.65)'}"
                v-loading="loading"
                :style="{
                    width: '100%'
                }">

                <el-table-column
                    width="50"
                    prop="serviceId"
                    label="SID">
                </el-table-column>

                <el-table-column
                    prop="effectName"
                    :label="$i18n.t('product.text34')">
                </el-table-column>

                <el-table-column
                    prop="effectRemarks"
                    :label="$i18n.t('product.text35')">
                </el-table-column>

                <el-table-column
                    prop="attributeName"
                    :label="$i18n.t('product.text36')">
                </el-table-column>

                <el-table-column
                    width="50"
                    prop="attributeId"
                    label="AID">
                </el-table-column>

                <el-table-column
                    prop="attributeRemarks"
                    :label="$i18n.t('product.text37')">
                </el-table-column>

                <el-table-column
                    :label="$i18n.t('product.text38')">
                    <template slot-scope="scope">
                        {{ $config.getMsgItemUtil($message.formatList, scope.row.format, 'id', 'name') }}
                    </template>
                </el-table-column>

                <el-table-column
                    width="140"
                    :label="$i18n.t('product.text39')">
                    <template slot-scope="scope">
                        <span style="white-space: pre-line;">{{ scope.row.remarks }}</span>
                    </template>
                </el-table-column>

                <el-table-column
                    :label="$i18n.t('product.text40')">
                    <template slot-scope="scope">
                        {{ $config.getMsgItemUtil($message.authorityList, scope.row.authority, 'id', 'name') }}
                    </template>
                </el-table-column>

                <el-table-column
                    :label="$i18n.t('product.text41')"
                    prop="remark">
                </el-table-column>

                <el-table-column width="100" :label="$i18n.t('product.text42')" :key="Math.random()">
                    <template slot-scope="scope">
                        <span v-if="productData.status != 3">
                            <el-button type="text" size="small" @click="editAttribute(scope.row, 1)">{{$i18n.t('product.text43')}}</el-button>
                            <el-button type="text" size="small" @click="delAttribute(scope.row)" style="color:#d45c34;">
                                {{$i18n.t('product.text45')}}
                            </el-button>
                        </span>
                        <span style="margin-left: 10px;">
                            <el-button type="text" size="small" @click="editAttribute(scope.row, 2)">{{$i18n.t('product.text44')}}</el-button>
                        </span>
                    </template>
                </el-table-column>

                <el-table-column width="200" :label="$i18n.t('product.text222')" :key="Math.random()">
                    <template slot-scope="scope">
                        <span v-if="productData.status != 3">
                            <el-button type="text" size="small" @click="addAttribute(scope.row)"
                                       v-show="scope.row.rowspan < 20">{{$i18n.t('product.text47')}}
                            </el-button>
                            <el-button type="text" size="small" @click="editEffect(scope.row, 0)">{{$i18n.t('product.text43')}}</el-button>
                            <el-button type="text" size="small" style="color:#d45c34;" @click="delEffect(scope.row)">{{$i18n.t('product.text45')}}
                            </el-button>
                        </span>
                        <span style="margin-left: 10px;">
                            <el-button type="text" size="small" @click="editEffect(scope.row, 1)">{{$i18n.t('product.text44')}}</el-button>
                        </span>
                    </template>
                </el-table-column>

            </el-table>

            <!-- 添加功能弹窗 -->
            <add-effect-dialog @getMsg="getMsg"></add-effect-dialog>

            <!-- 属性弹窗 -->
            <attribute-dialog @getMsg="getMsg"></attribute-dialog>

            <!-- 修改功能弹窗 -->
            <edit-effect-dialog @getMsg="getMsg"></edit-effect-dialog>

            <!-- 添加功能 -->
            <add-standard-functions-dialog @getMsg="getMsg" @getStandardList="getStandardList"></add-standard-functions-dialog>

        </div>

        <div class="body" style="margin-top: 20px;">

            <div class="header_btn">
                <div class="title">
                    {{$i18n.t('product.text48')}}
                    <el-popover
                        placement="top-start"
                        title=""
                        width="200"
                        trigger="hover"
                        :content="$i18n.t('product.text49')">
                        <i
                            :class="icon"
                            @mouseover="icon = 'question_coicon icon-yuanyin'"
                            @mouseleave="icon = 'question_alicon iconfont_al icon-yiwen'" slot="reference"></i>
                    </el-popover>
                </div>
            </div>

            <el-row :gutter="20" style="margin-top: 30px">
                <el-empty :description="$i18n.t('product.text50')" :image-size="100" v-if="configList.length === 0"></el-empty>
                <el-col :span="8" v-for="(item, index) in configList" :key="index">
                    <div class="config_item">
                        <div class="config_item_title">
                            {{ item.title }}
                        </div>
                        <el-switch
                            :disabled="item.isDisabled"
                            @change="switchConfig($event, item.code, index)"
                            style="float: right;"
                            v-model="item.isOpen">
                        </el-switch>
                        <div class="config_item_details" :title="item.details">
                            {{ item.details }}
                        </div>
                        <div style="text-align: right;position: absolute;bottom: 10px;right: 10px;">
                            <el-link :disabled="!item.isOpen" @click="configuration(index)" type="primary" style="padding: 0;" v-if="item.code === '10000000155826'">
                                {{$i18n.t('product.text223')}}
                            </el-link>
                            <el-link :href="item.descriptionUrl" target="_blank" type="primary" style="padding: 0;" v-else>
                                {{$i18n.t('product.text7')}}
                            </el-link>
                        </div>
                    </div>
                </el-col>

            </el-row>

        </div>

        <el-dialog
            :close-on-click-modal="false"
            :close-on-press-escape="false"
            :title="$i18n.t('product.text224')"
            :visible.sync="configurePasswordCardDialogVisible"
            width="40%"
            @close="closeDialog"
            @open="openDialog"
            center>

            <el-form ref="passwordCardForm" class="password-card-form" size="small" :rules="rules" :model="passwordCardForm" label-width="150px">
                <el-form-item :label="$i18n.t('product.text225')" prop="innerUri">
                    <el-input v-model="passwordCardForm.innerUri" :placeholder="$i18n.t('product.text226')"></el-input>
                </el-form-item>
                <el-form-item :label="$i18n.t('product.text227')" required>
                    <el-select v-model="passwordCardForm.type" @change="selectChange" :placeholder="$i18n.t('product.text228')">
                        <el-option
                            :label="$i18n.t('product.text229')"
                            :value="1">
                        </el-option>
                        <el-option
                            :label="$i18n.t('product.text230')"
                            :value="2">
                        </el-option>
                    </el-select>
                </el-form-item>
                <el-form-item :label="$i18n.t('product.text231')" prop="backgroundImage" v-if="passwordCardForm.type === 1">
                    <el-upload
                        list-type="picture-card"
                        :action="$message.uploadImgUrl"
                        :data="{
                               bucket: 'netConfig',
                               holdName: 'N' // 保持上传文件名不变
                            }"
                        :headers="{
                                accessToken: $token.getToken().accessToken,
                                refreshToken: $token.getToken().refreshToken,
                                language: language
                            }"
                        accept=".png"
                        :limit="1"
                        :on-success="(response, file, fileList) => onSuccess(response, file, fileList)"
                        :on-error="onError"
                        :file-list="files"
                        :before-upload="(file) => onbeforeunload(file)"
                        :on-remove="(file, fileList) => handleRemove(file, fileList)">
                        <i class="el-icon-plus" :id="`picture-card`" v-show="!passwordCardForm.backgroundImage"></i>
                    </el-upload>
                </el-form-item>
                <el-form-item :label="$i18n.t('product.text232')" prop="backgroundColor" v-if="passwordCardForm.type === 2">
                    <el-color-picker v-model="passwordCardForm.backgroundColor"></el-color-picker>
                </el-form-item>
            </el-form>

            <span slot="footer" class="dialog-footer">
                <el-button @click="closeDialog">{{$i18n.t('member.text33')}}</el-button>
                <el-button type="primary" :loading="dialogLoading" @click="modify()">{{$i18n.t('member.text34')}}</el-button>
            </span>
        </el-dialog>

    </div>
</template>

<script>

import {mapState, mapActions} from "vuex";
import addEffectDialog from '@/views/product/views/effect/components/dialog/addEffectDialog';
import attributeDialog from '@/views/product/views/effect/components/dialog/attributeDialog';
import editEffectDialog from '@/views/product/views/effect/components/dialog/editEffectDialog';
import AddStandardFunctionsDialog from "@/views/product/views/effect/components/dialog/addStandardFunctionsDialog";

export default {

    // onLoad: function (val) {
    //     this .routerVal = val;
    //     uni.$on('cartList',res=> {
    //         console.log("res", res);
    //         let msg = JSON.parse(JSON.stringify(this.orderMessage));
    //         msg.cartList = res;
    //         this.orderMessage = msg
    //         console.log("this", this.orderMessage.cartList)
    //     })
    // },

    computed: {
        ...mapState('product', ['productMsg'])
    },

    watch: {

        productMsg: {
            handler(newVal) {
                this.productData = newVal;
                this.queryServiceList();
                this.getStandardList();
            }
        }

    },

    components: {
        AddStandardFunctionsDialog,
        addEffectDialog,
        attributeDialog,
        editEffectDialog
    },

    data() {

        return {

            loading: false,

            loadingStandard: false,

            dialogLoading: false,

            configurePasswordCardDialogVisible: false,

            tableHeight: 50,

            effectList: [],

            effectStandardList: [],

            //未选标准列表
            standardList: [],

            productData: {},

            icon: "question_alicon iconfont_al icon-yiwen",

            //配置服务列表
            configList: [],

            //准备上传的配置服务信息
            configureService: [],

            hoverIndex: null,

            passwordCardForm: {
                innerUri: '',
                backgroundImage: "",
                backgroundColor: "",
                type: 1,
                index: 0
            },

            rules: {
                innerUri: [{required: true, message: this.$i18n.t('product.text226'), trigger: "blur"}],
                backgroundImage: [{ required: true, message: this.$i18n.t('product.text233'), trigger: 'change' }],
                backgroundColor: [{ required: true, message: this.$i18n.t('product.text234'), trigger: 'change' }],
            },

            language: localStorage.getItem('language'),

            files: []

        }

    },

    mounted() {
        this.productData = this.productMsg;
        this.getMsg();
    },

    destroyed() {
        window.removeEventListener('resize', this.setHeight);
    },

    beforeUpdate () {
        // table数据更新后，重新渲染table避免闪动
        this.$nextTick(() => {
            this.$refs['effectStandardTable'].doLayout()
            this.$refs['effectTable'].doLayout()
        })
    },

    methods: {

        ...mapActions('effect', ['queryProductServiceList', 'deleteProductService', 'deleteProductAttribute', 'exportFunction', 'getStandardServiceList']),
        ...mapActions('product', ['queryConfigureServiceList', 'modifyProduct']),

        setHeight() {
            this.tableHeight = window.innerHeight - 45 - 240;
        },

        modify() {
            this.$refs.passwordCardForm.validate((valid) => {
                if (valid) {
                    this.dialogLoading = true;
                    let item = this.configureService;
                    item[this.passwordCardForm.index].innerUri = this.passwordCardForm.innerUri;
                    item[this.passwordCardForm.index].backgroundImage = this.passwordCardForm.backgroundImage;
                    item[this.passwordCardForm.index].backgroundColor = this.passwordCardForm.backgroundColor;
                    this.modifyProduct({
                        code: this.productData.code,
                        // name: this.productData.name,
                        configureService: JSON.stringify(item)
                    }).then(res => {

                        let configServer = this.productData.configureService ? JSON.parse(this.productData.configureService) : [];
                        let configServerList = Array.isArray(configServer) ? configServer : [configServer];
                        configServerList[this.passwordCardForm.index].innerUri = this.passwordCardForm.innerUri;
                        configServerList[this.passwordCardForm.index].backgroundImage = this.passwordCardForm.backgroundImage;
                        configServerList[this.passwordCardForm.index].backgroundColor = this.passwordCardForm.backgroundColor;
                        this.productData.configureService = JSON.stringify(configServerList);
                        this.$dialog.showMessage(this.$i18n.t('product.text235'), this.$config.TOAST_SUCCESS);
                        this.queryServiceList();
                        this.closeDialog()
                    }, err => {
                        this.$dialog.showMessage(err.resultMsg, this.$config.TOAST_ERROR);
                    }).finally(() => {
                        this.dialogLoading = false;
                    })
                }
            })
        },

        selectChange(v) {
            if (v === 1) {
                this.passwordCardForm.backgroundColor = ""
            } else {
                this.passwordCardForm.backgroundImage = ""
            }
        },

        closeDialog() {
            this.$refs.passwordCardForm.resetFields();
            this.passwordCardForm = {
                innerUri: '',
                backgroundImage: "",
                backgroundColor: "",
                type: 1,
                index: 0
            }
            this.files = [];
            this.configurePasswordCardDialogVisible = false;
        },

        openDialog() {
            this.$nextTick(() => {
                if (this.passwordCardForm.type === 1 && this.passwordCardForm.backgroundImage) {
                    document.getElementById(`picture-card`).parentNode.style.display = this.passwordCardForm.backgroundImage ? "none" : "";
                    this.files = [{
                        uuid: '1',
                        url: this.passwordCardForm.backgroundImage
                    }]
                }
            })
        },

        onSuccess(response, file, fileList) {
            if (response.success) {
                this.files = fileList;
                this.passwordCardForm.backgroundImage = response.result.fileList[0].url;
            } else {
                this.$dialog.showMessage(response.resultMsg, this.$config.TOAST_ERROR);
                document.getElementById(`picture-card`).parentNode.style.display = ""
            }
        },

        onbeforeunload(file) {
            document.getElementById(`picture-card`).parentNode.style.display = "none"
            const isLt2M = file.size < (2*1024*1024);

            if (!isLt2M) {
                console.log(this.$i18n.t('product.text237'))
                this.$dialog.showMessage(this.$i18n.t('product.text237'), this.$config.TOAST_WARNING);
                return false;
            }
            const isSize = new Promise(function (resolve, reject) {
                let width = 350;
                let height = 100;
                let _URL = window.URL || window.webkitURL;
                let image = new Image();
                image.onload = function () {
                    let valid = image.width == width && image.height == height;
                    valid ? resolve() : reject();
                };
                image.src = _URL.createObjectURL(file);
            }).then(
                () => {
                    return file;
                },
                () => {
                    this.$dialog.showMessage(this.$i18n.t('product.text238'), this.$config.TOAST_WARNING);
                    return Promise.reject();
                }
            );
            return isLt2M && isSize;
        },

        handleRemove(file, fileList) {
            document.getElementById(`picture-card`).parentNode.style.display = ""
            this.passwordCardForm.backgroundImage = "";
            this.files = [];
        },

        onError(err, file, fileList) {
            document.getElementById(`picture-card`).parentNode.style.display = ""
            this.$dialog.showMessage(err.resultMsg, this.$config.TOAST_ERROR);
            console.log('onError', err, file, fileList)
        },

        switchConfig(isOpen, code, index) {
            let item = this.configureService;
            item[index].onOff = isOpen ? 1 : 0;
            this.modifyProduct({
                code: this.productData.code,
                // name: this.productData.name,
                configureService: JSON.stringify(item)
            }).then(res => {
                this.$dialog.showMessage(this.$i18n.t('product.text51'), this.$config.TOAST_SUCCESS);
            }, err => {
                this.$dialog.showMessage(err.resultMsg, this.$config.TOAST_ERROR);
            })
        },

        configuration(index) {
            let configServer = this.productData.configureService ? JSON.parse(this.productData.configureService) : [];
            let configServerList = Array.isArray(configServer) ? configServer : [configServer];
            let item = configServerList[index]
            this.passwordCardForm = {
                innerUri: item.innerUri ? item.innerUri : '',
                backgroundImage: item.backgroundImage ? item.backgroundImage : "",
                backgroundColor: item.backgroundColor ? item.backgroundColor : "",
                type: item.backgroundImage ? 1 : (item.backgroundColor ? 2 : 1),
                index: index
            }
            this.configurePasswordCardDialogVisible = true;
        },

        tableRowClassName({row}){
            if (this.hoverIndex === row.code) {
                return "hover-bg";
            }
        },

        /**
         * ⿏标移⼊表格行
         * @param row
         * @param column
         * @param cell
         * @param event
         */
        cellMouseEnter(row, column, cell, event) {
            this.hoverIndex = row.code;
        },

        /**
         * ⿏标移出表格行
         */
        cellMouseLeave() {
            this.hoverIndex = null;
        },

        /**
         * 查询当前品类的配置服务列表
         */
        queryServiceList() {
            this.queryConfigureServiceList({
                categoryCode: this.productData.categoryCode
            }).then(res => {
                let list = [];

                //产品信息中所带的配置服务信息
                let configServer = this.productData.configureService ? JSON.parse(this.productData.configureService) : [];
                let configServerList = Array.isArray(configServer) ? configServer : [configServer];

                //判断产品详情中是否有配置服务信息
                if (configServerList.length > 0) {

                    //如果产品详情中存在配置服务信息
                    //则将产品详情中的配置服务信息与跟据品类查询出的配置服务列表进行信息同步
                    console.log(configServerList, res.result)
                    res.result.forEach((item, index) => {
                        console.log(configServerList[index], index)
                        list.push({
                            id: item.id,
                            code: item.code,
                            //判断该配置服务是否为必选
                            title: item.name + (item.associationType === "1" ? this.$i18n.t('product.text52') : ""),
                            details: item.remark,
                            descriptionUrl: item.descriptionUrl,
                            //当配置服务为必选或者开关为打开时将卡片开关设置为打开
                            isOpen: item.associationType === "1",
                            //判断该配置服务是否为必选，必选时开关设置为禁用
                            isDisabled: item.associationType === "1",
                            innerUri: configServerList[index] && configServerList[index].innerUri ? configServerList[index].innerUri : '',
                            backgroundImage: configServerList[index] && configServerList[index].backgroundImage ? configServerList[index].backgroundImage : "",
                            backgroundColor: configServerList[index] && configServerList[index].backgroundColor ? configServerList[index].backgroundColor : ""
                        });
                        configServerList.forEach(val => {
                            if (val.code === item.code) {
                                list[index].isOpen = val.onOff === 1 || list[index].isDisabled;
                            }
                        })
                    })

                    let newConfigServerList = [];

                    list.forEach(item => {
                        let newConfigServerListItem = {
                            code: item.code,
                            onOff: item.isOpen ? 1 : 0
                        }
                        if (item.code === "10000000155826") {
                            newConfigServerListItem.innerUri = item.innerUri ? item.innerUri : '';
                            newConfigServerListItem.backgroundImage = item.backgroundImage ? item.backgroundImage : '';
                            newConfigServerListItem.backgroundColor = item.backgroundColor ? item.backgroundColor : '';
                        }
                        newConfigServerList.push(newConfigServerListItem)
                    })
                    configServerList = newConfigServerList;

                } else {

                    //如果产品中不存在配置服务信息则直接采用品类查询出的配置服务列表
                    res.result.forEach((item, index) => {
                        list.push({
                            id: item.id,
                            code: item.code,
                            //判断该配置服务是否为必选
                            title: item.name + (item.associationType === "1" ? this.$i18n.t('product.text52') : ""),
                            details: item.remark,
                            descriptionUrl: item.descriptionUrl,
                            //当配置服务为必选时将卡片开关设置为打开
                            isOpen: item.associationType === "1",
                            //判断该配置服务是否为必选，必选时开关设置为禁用
                            isDisabled: item.associationType === "1"
                        });
                        let configServerListItem = {
                            code: item.code,
                            onOff: item.associationType === "1" ? 1 : 0
                        }
                        console.log(item.code, item.code === '10000000155826')
                        if (item.code === '10000000155826') {
                            configServerListItem.innerUri = '';
                            configServerListItem.backgroundImage = '';
                            configServerListItem.backgroundColor = '';
                        }
                        configServerList.push(configServerListItem)
                    })

                }
                this.configList = list;
                this.configureService = configServerList;
                console.log('configServerList => ', configServerList)
                //每次打开时都将配置服务信息同步上传到产品信息中
                this.modifyProduct({
                    code: this.productData.code,
                    // name: this.productData.name,
                    configureService: JSON.stringify(this.configureService)
                }).then(res => {
                }, err => {
                    this.$dialog.showMessage(err.resultMsg, this.$config.TOAST_ERROR);
                })
            }, err => {
                this.$dialog.showMessage(err.resultMsg, this.$config.TOAST_ERROR);
            })
        },

        /**
         * 处理格式化功能定义数据
         * @param {*} data
         */
        effectDataHandle(data, type) {
            let list = this.$config.deepCopy(data);
            console.log(list)
            let effectList = [];
            list.forEach(item => {
                if (type !== item.type) return;
                item.productAttributeList.forEach((item1, index1) => {
                    console.log(item1.enumValues)
                    effectList.push({
                        code: item.code,
                        attributeCode: item1.code,
                        attributeId: item1.attributeId,
                        effectName: item.name,
                        effectRemarks: item.serviceKey,
                        effectType: item.type,
                        attributeName: item1.name,
                        attributeRemarks: item1.attributeKey,
                        authority: item.authority,
                        format: item1.dataType,
                        rowspan: item.productAttributeList.length > 1 ? (index1 == 0 ? item.productAttributeList.length : 0) : 1,
                        colspan: index1 == 0 ? 1 : 0,
                        remarks: item1.remark,
                        enumList: (!item1.enumValues || item1.enumValues == "[]") ? [] : JSON.parse(item1.enumValues),
                        endNum: item1.maxIntValue,
                        startNum: item1.minIntValue,
                        unit: item1.unit,
                        step: item1.stepIntValue,
                        string: item1.maxLength,
                        remark: item.remark,
                        serviceId: item.serviceId
                    })
                })
            });
            return effectList;
        },

        /**
         * 获取自定义产品功能列表
         */
        getMsg() {
            this.loading = true;
            this.loadingStandard = true;
            this.queryProductServiceList({
                productCode: this.$route.query.id
            }).then(res => {
                if (!res.result) return
                this.effectList = this.effectDataHandle(res.result, 2);
                this.effectStandardList = this.effectDataHandle(res.result, 1);
                console.log(this.effectList, this.effectStandardList)
            }, err => {
                this.$dialog.showMessage(err.resultMsg, this.$config.TOAST_ERROR);
            }).finally(() => {
                setTimeout(() => this.loading = false, 500)
                setTimeout(() => this.loadingStandard = false, 500)
            })
        },


        getStandardList() {
            this.getStandardServiceList({
                productCode: this.$route.query.id,
                categoryCode: this.productData.categoryCode
            }).then(res => {
                if (!res.result) return;
                let list = [];
                //过滤以选项（ps:应产品要求去掉筛选，只要是有标准功能就不置灰按钮）
                res.result.forEach(item => {
                    // if (!item.selected) {
                        list.push(item);
                    // }
                })
                this.standardList = list;
            }, err => {
                this.$dialog.showMessage(err.resultMsg, this.$config.TOAST_ERROR);
            })
        },

        /**
         * 设置列表合并
         * @param row 行数据
         * @param columnIndex 行数据列号
         * @returns {{colspan: (string|*), rowspan: (string|*)}}
         */
        objectSpanMethod({row, columnIndex}) {
            if (columnIndex == 0 || columnIndex == 1 || columnIndex == 2 || columnIndex == 8 || columnIndex == 9 || columnIndex == 11) {
                return {
                    rowspan: row.rowspan,
                    colspan: row.colspan,
                };
            }
        },

        /**
         * 导出自定义功能excel文件
         */
        leadingOut() {
            this.exportFunction({
                productCode: this.$route.query.id
            }).then(res => {
                console.log(res);
                if (res.data instanceof Blob) {
                    const fileName =
                        this.productData.pid + '.xls';
                    this.downFile(res.data, fileName);
                    return;
                }
                this.$dialog.showMessage(res.data.resultMsg, this.$config.TOAST_ERROR);
            }, err => {
                this.$dialog.showMessage(err.resultMsg, this.$config.TOAST_ERROR);
            })
        },

        /**
         * 文件下载
         * @param data
         * @param fileName
         * @returns {boolean}
         */
        downFile(data, fileName) {
            if (!data) {
                return false;
            }
            // 下面就是DOM操作 1.添加一个a标签 2.给a标签添加了属性 3.给他添加了点击事件(点击后移除)
            const url = window.URL.createObjectURL(new Blob([data]));
            const link = document.createElement("a");
            link.style.display = "none";
            link.href = url;
            link.setAttribute("download", fileName);
            document.body.appendChild(link);
            link.click();
            link.remove();
        },

        /**
         * 编辑属性
         * @param row 行数据
         */
        editAttribute(row, type) {
            this.$store.commit("effect/SET_ATTRIBUTE_DIALOG_TYPE", type);
            this.$store.commit("effect/SET_ATTRIBUTE_MSG_ITEM", row);
            this.$store.commit("effect/SET_ATTRIBUTE_DIALOG_VISIBLE", true);
        },

        /**
         * 删除属性
         * @param row 行数据
         */
        delAttribute(row) {
            this.$dialog.showConfirm({
                content: this.$i18n.t('product.text53'),
                title: this.$i18n.t('product.text54'),
                btn1: this.$i18n.t('product.text45'),
                btn2: this.$i18n.t('product.text55'),
                icon: 2
            }, () => {
                this.deleteProductAttribute({
                    productCode: this.$route.query.id,
                    attributeCode: row.attributeCode
                }).then(res => {
                    this.$dialog.showMessage(this.$i18n.t('product.text56'), this.$config.TOAST_SUCCESS);
                    this.getMsg();
                }, err => {
                    console.log(err);
                    this.$dialog.showMessage(err.resultMsg, this.$config.TOAST_ERROR);
                })
            })
        },

        /**
         * 添加属性
         * @param row 行数据
         */
        addAttribute(row) {
            this.$store.commit("effect/SET_ATTRIBUTE_DIALOG_TYPE", 0);
            this.$store.commit("effect/SET_ATTRIBUTE_MSG_ITEM", row);
            this.$store.commit("effect/SET_ATTRIBUTE_DIALOG_VISIBLE", true);
        },

        /**
         * 编辑功能
         * @param row 行数据
         */
        editEffect(row, type) {
            this.$store.commit("effect/SET_MODIFY_EFFECT_DIALOG_VISIBLE", true);
            this.$store.commit("effect/SET_EFFECT_DIALOG_TYPE", type);
            this.$store.commit("effect/SET_EFFECT_LIST_ITEM", row);
        },

        /**
         * 删除功能
         * @param row 行数据
         */
        delEffect(row) {
            console.log(row);
            this.$dialog.showConfirm({
                content: this.$i18n.t('product.text57'),
                title: this.$i18n.t('product.text54'),
                btn1: this.$i18n.t('product.text45'),
                btn2: this.$i18n.t('product.text55'),
                icon: 2
            }, () => {
                this.deleteProductService({
                    productCode: this.$route.query.id,
                    serviceCode: row.code
                }).then(res => {
                    this.$dialog.showMessage(this.$i18n.t('product.text56'), this.$config.TOAST_SUCCESS);
                    this.getMsg();
                    this.getStandardList();
                }, err => {
                    console.log(err);
                    this.$dialog.showMessage(err.resultMsg, this.$config.TOAST_ERROR);
                })
            })
        },

        /**
         * 新增自定义功能
         */
        showCustom() {
            this.$store.commit("effect/SET_ADD_EFFECT_DIALOG_VISIBLE", true);
        },

        /**
         * 新增标准功能
         */
        showStandard() {
            this.$store.commit("effect/SET_ADD_STANDARD_FUNCTIONS_DIALOG_VISIBLE", true);
        }

    }

}

</script>

<style scoped>
.body {
    width: calc(100% - 60px) !important;
    box-shadow: 0 0 4px 0 #BCBCBC;
    border-radius: 8px;
    padding: 16px 20px;
    margin: 10px;
}

.el-card {
    height: 100%;
}

/deep/ .el-card__header {
    padding: 15px !important;
    text-align: left;
    font-size: 16px;
}

/deep/ .el-breadcrumb__inner, /deep/ .el-breadcrumb__separator {
    font-weight: bold !important;
}

.header_btn {
    width: 100%;
}

.btn {
    float: right;
    margin-bottom: 10px;
}

.title {
    float: left;
    margin-top: 8px;
    font-size: 16px;
    color: #333333;
}

/deep/ .el-table__header-wrapper {
    border-radius: 8px 8px 0 0;
}

.el-table {
    border-radius: 8px;
}

.question_coicon {
    color: #3296FA;
    font-size: 16px;
    cursor: pointer;
    font-family: "iconfont" !important;
    font-style: normal;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
}

.question_alicon {
    color: #3296FA;
    font-size: 16px;
    cursor: pointer;
}

.config_item {
    border: 1px solid #CDCDCD;
    height: 100px;
    border-radius: 5px;
    padding: 10px;
    position: relative;
}

.config_item_title {
    float: left;
    font-weight: bold;
    font-size: 14px;
}

.config_item_details {
    margin-top: 30px;
    font-weight: 400;
    font-size: 12px;
    color: rgba(0, 0, 0, 0.40);
    overflow: hidden;
    text-overflow: ellipsis;
    width: 100%;
    display: -webkit-box;
    -webkit-box-orient: vertical;
    -webkit-line-clamp: 3;
    word-break: break-all;
}

.el-col-8 {
    margin-top: 20px;
}

/deep/ .el-table__body .el-table__row.hover-bg td{
    background-color: #DEE0E5 !important;
}

/deep/ .el-upload-list__item, /deep/ .el-upload--picture-card {
    width: 350px!important;
    height: 100px!important;
}

/deep/ .el-form-item__content > div {
    display: flex;
}

/deep/ .el-upload--picture-card {
    line-height: 110px!important;
    margin-bottom: 20px;
    /*margin-right: 8px;*/
}

/deep/ .el-upload-list--picture-card .el-progress {
    /*width: 80px!important;*/
    /*height: 80px!important;*/
    zoom: 70%;
}

</style>
